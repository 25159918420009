<template>
  <div>
    <a-row :gutter="[16, 16]" class="mt-4">
      <a-col :span="6">
        <div @click.prevent="toDetail('rentang_penghasilan', 'Lebih Dari 20 Juta')">
          <card-counter
            typeCard="primary"
            title="Berpenghasilan > 20 Juta"
            :valueCard="
              dataFinance.count_all_murid_orangtua_penghasilan_lebih_dari_20jt
            "
            isDetail
            :filterField="{ penghasilan_bulanan: 6 }"
          />
        </div>
      </a-col>
      <a-col :span="6">
        <div @click.prevent="toDetail('jenis_bantuan', 'kps_pkh')">
          <card-counter
            typeCard="warning"
            title="Penerima KPS/PKH"
            :valueCard="dataFinance.count_all_murid_kps_pkh"
            isDetail
            :filterField="{ kps_pkh: true }"
          />
        </div>
      </a-col>
      <a-col :span="6">
        <div @click.prevent="toDetail('jenis_bantuan', 'kip')">
          <card-counter
            typeCard="info"
            title="Penerima KIP"
            :valueCard="dataFinance.count_all_murid_kip"
            isDetail
            :filterField="{ kip: true }"
          />
        </div>
      </a-col>
      <a-col :span="6">
        <div @click.prevent="toDetail('jenis_bantuan', 'pip')">
          <card-counter
            typeCard="danger"
            title="Murid Layak PIP"
            :valueCard="dataFinance.count_all_murid_pip"
            isDetail
            :filterField="{ pip: true }"
          />
        </div>
      </a-col>
      <a-col :span="24">
        <div class="border rounded p-3">
          <div class="d-flex align-items-center mb-4">
            <h4>Pemetaan Berdasarkan Penghasilan Orang Tua</h4>
            <a-select
              size="large"
              class="ml-auto w-25"
              v-model="selectedGaji"
              @change="handleChangeGaji"
            >
              <a-select-option
                v-for="item in monthlyRevenueList"
                :key="item.key"
              >
                {{ item.title }}
              </a-select-option>
            </a-select>
          </div>
          <div style="height: 400px; width: 100%">
            <location-cluster
              :locations="filteredDataMapMuridPenghasilan"
              tooltipType="penghasilan"
              :options="clusterOptions"
            />
          </div>
        </div>
      </a-col>
      <a-col :span="16">
        <div class="border rounded p-3">
          <h4 class="mb-4">Penghasilan Orang Tua</h4>
          <pie-chart
            :labels="mappedPenghasilanOrangtua"
            :data="dataFinance.data_chart_penghasilan"
            :options="layakPIPDataSet.options"
          />
        </div>
      </a-col>
      <a-col :span="8">
        <div class="border rounded p-3">
          <h4 class="mb-4">Alasan Murid Layak PIP</h4>
          <div class="table-responsive">
            <table class="table table-borderless text-gray-6 mb-0">
              <tbody>
                <tr
                  v-for="item in dataFinance.data_chart_alasan_layak_pip"
                  :key="item.nama"
                >
                  <td class="pl-0 font-weight-bold">
                    <p>{{ item.nama }}</p>
                  </td>
                  <td class="text-right">
                    <p class="font-weight-bold">{{ item.count }}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { alasanLayakPIPLists, monthlyRevenueList } from '@/helpers/listsPpdbSelect'
const LocationCluster = () => import('@/components/app/shared/LocationCluster')
const CardCounter = () => import('@/components/app/shared/CardCounter')
const layakPIPDataSet = {
  data: [30, 13, 17, 4, 89, 143, 5],
  options: {
    maintainAspectRatio: false,
  },
}
const locations = [
  {
    latitude: -6.313286169605539,
    longitude: 106.91139155076502,
    jarak: 13.8,
    waktu_tempuh_ke_sekolah: 2860,
    address: 'Balai Uji Standar Karantina Ikan Pengendalian Mutu Dan Keamanan Hasil Perikanan, Jalan Harapan I, RW 05, Setu, Cipayung, East Jakarta, Jakarta Special Capital Region, 13890, Indonesia',
  },
  {
    latitude: -6.313976234195372,
    longitude: 106.9093661408388,
    jarak: 13.2,
    waktu_tempuh_ke_sekolah: 2760,
    address: 'Jalan Raya Mabes Hankkam, RW 05, Setu, Cipayung, East Jakarta, Jakarta Special Capital Region, 13890, Indonesia',
  },
  {
    latitude: -6.314516140615398,
    longitude: 106.91053561044883,
    jarak: 13.5,
    waktu_tempuh_ke_sekolah: 2810,
    address: 'Jalan Puskesmas, RW 03, Setu, Cipayung, East Jakarta, Jakarta Special Capital Region, 13890, Indonesia',
  },
  {
    latitude: -6.265561,
    longitude: 106.784327,
    jarak: 10.9,
    waktu_tempuh_ke_sekolah: 2516,
    address: 'Jalan Metro Pondok Indah Kav. IV, RT.1/RW.16, Pd. Pinang, Kec. Kby. Lama, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12310, Indonesia',
  },
  {
    latitude: -6.274966,
    longitude: 106.773429,
    jarak: 14.2,
    waktu_tempuh_ke_sekolah: 2887,
    address: 'Jl. Ciputat Raya, Indonesia',
  },
  {
    latitude: -6.201186,
    longitude: 106.842276,
    jarak: 7.3,
    waktu_tempuh_ke_sekolah: 1673,
    address: 'Jl. Pangeran Diponegoro, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta, Indonesia',
  },
  {
    latitude: -6.2348,
    longitude: 106.729195,
    jarak: 18.5,
    waktu_tempuh_ke_sekolah: 2073,
    address: 'Jl. Caplin Satu, Kec. Larangan, Kota Tangerang, Banten 15154, Indonesia',
  },
  {
    latitude: -6.15525,
    longitude: 106.608518,
    jarak: 23.5,
    waktu_tempuh_ke_sekolah: 2573,
    address: 'Periuk Jaya, Periuk, Tangerang City, Banten, Indonesia',
  },
  {
    latitude: -6.169474,
    longitude: 106.626721,
    jarak: 20.9,
    waktu_tempuh_ke_sekolah: 2373,
    address: 'Jl. Ks. Tubun No.10, RT.001/RW.001, Ps. Baru, Kec. Karawaci, Kota Tangerang, Banten 15112, Indonesia',
  },
]
const PieChart = () => import('@/components/app/shared/ChartJS/Pie')
export default {
  components: {
    PieChart,
    LocationCluster,
    CardCounter,
  },
  props: {
    dataFinance: {
      type: Object,
      required: true,
    },
    tahunMasuk: {
      type: String,
    },
  },
  data() {
    return {
      layakPIPDataSet,
      alasanLayakPIPLists,
      monthlyRevenueList,
      locations,
      clusterOptions: {},
      selectedGaji: 6,
    }
  },
  methods: {
    handleMenuClick(e) {
      this.schoolYearActive = e.key
    },
    handleChangeGaji(e) {
      this.selectedGaji = e
    },
    formatValue(value) {
      return `${Number(value).toFixed(0)}`
    },
    toDetail(key, value) {
      this.$router.push({ name: 'Detail Student Candidate Admin', query: { [key]: value, tahun_masuk: this.tahunMasuk } })
    },
  },
  computed: {
    filteredDataMapMuridPenghasilan() {
      if (this.dataFinance.data_map_murid_penghasilan.length) {
        return this.dataFinance.data_map_murid_penghasilan.filter(data => data.key_list_penghasilan === this.selectedGaji)
      }
      return []
    },
    mappedAlasanLayakPIP() {
      return this.alasanLayakPIPLists.map(row => {
        return {
          ...row,
          total: Math.floor(Math.random() * (200 - 5 + 1)) + 5,
        }
      })
    },
    mappedPenghasilanOrangtua() {
      return this.monthlyRevenueList.map(row => row.title)
    },
  },
}
</script>

<style>
</style>
